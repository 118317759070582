<template>
  <!-- <div class="bg-darkblue"></div> -->
  <div>
      <nav class="navigation">
          <div class="nav_image">
              <img src="../../assets/image/welcome-2.jpg" alt="navigation welcome image">
          </div>
          <ul class="menu ml-0 p-0">
              <li class="m-0 pl-0 d-flex justify-content-center align-items-center"><a href="https://dk.ge/Home">ვაკანსიები</a></li>
              <li class="m-0 pl-0 d-flex justify-content-center align-items-center"><a href="https://academy.dk.ge/">აკადემია</a></li>
              <li class="m-0 pl-0 d-flex justify-content-center align-items-center"><a href="https://re.dk.ge/Investments">მშენებლობა</a></li>
              <li class="m-0 pl-0 d-flex justify-content-center align-items-center"><a href="https://re.dk.ge/">უძრავი ქონება</a></li>
          </ul>
      </nav>
  </div>
</template>

<script>
export default {
  methods: {
    setCookie(status) {
      const expirationDate = new Date();
      // expirationDate.setTime(expirationDate.getTime() + 3 * 60 * 60 * 1000); // Adding 3 hours in milliseconds
      expirationDate.setTime(expirationDate.getTime() + 10 * 1000); // Adding 10 second in milliseconds

      document.cookie = "dk=WelcomePage; expires=" + expirationDate.toUTCString();

      if (document.cookie) {
        if (status === 'vacancy') {
          window.location.href = `https://dk.ge/`;
        }
        else if (status === 'academy') {
          window.location.href = `https://academy.dk.ge/`;
        }
        else if (status === 'development') {
          window.location.href = `https://re.dk.ge/NewDevelopments`;
        }
        else if (status === 'home') {
          window.location.href = `https://re.dk.ge/`;
        }
      } else {
        alert("Cookie can't be set!");
      }
    },
    // checkCookie() {
    //   const value = document.cookie;
    //   const checkCookie = value.match("dk=WelcomePage");
    //   if (checkCookie) {
    //     this.$router.push('/Home');
    //   }
    // }
  },
  beforeCreate() {
    // this.checkCookie();

    const body = document.body;
    if (body) {
      body.style.background = '#fff'
    }
  }
}
</script>

<style scoped>
.navigation {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-top: 50px;
  padding-bottom: 25px;
}
.nav_image {
  max-width: 280px;
  max-height: 280px;
  border: 1px solid #000;
  border-radius: 50%;
  overflow: hidden;;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-darkblue {
  position: absolute;
  /* inset: 0; */
  width: 100%;
  height: 100%;
  background-color: #080067;
  z-index: -1;
}

.menu {
  margin: 0;
  margin-top: 30px;
  list-style: none;

  text-align: center;
  width: 100%;
  max-width: 280px;
  
}

.menu li {
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid transparent;
  margin: 0;

  cursor: pointer;

  position: relative;

  /* transition: 0.5s; */
}
/* @media screen and (min-width: 768px) {
  .menu li:hover {
      border-bottom: 1px solid #fff;
  }
} */

.menu li a {
  font-family: var(--Third-Font);
  -moz-font-feature-settings: "case";
  -webkit-font-feature-settings: "case";
  font-feature-settings: "case" on;

  font-size: 25px;
  color: #000;


  display: inline-block;
  position: relative;

  overflow: hidden;
}
@media screen and (max-width: 768px) {
  /* .menu {
    max-width: 280px;
  } */

  .menu li {
      border-bottom: 1px solid transparent;
      border-top: 1px solid #000;
  }

  .menu li:last-child {
      border-bottom: 1px solid #000;
  }
}
@media screen and (min-width: 768px) {
  @media (hover: hover) and (pointer: fine) {
  /* .menu li:hover {
      color: #fff;
  } */

  .menu li a:hover::before {
      transform-origin: 0% 50%;
      -webkit-transform: scale3d(1, 1, 1);
      -moz-transform: scale3d(1, 1, 1);
      -ms-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
  }
}
}


.menu li a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid currentColor;
  transform-origin: 100% 50%;
  -webkit-transform: scale3d(0, 1, 1);
  -moz-transform: scale3d(0, 1, 1);
  -ms-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
}




</style>





<!-- <template>
  <loading :active="isLoading"
           :can-cancel="true"
           :on-cancel="onCancel"

           :z-index="10000"
           :color="'var(--First-Color)'"></loading>

  <div class="container-fluid">
    <div class="card mt-4 align-items-center">
      <img
          class="card-img-top"
          alt="image"
          src="../../assets/image/Ellipse104.png">
      <div class="card-body d-flex flex-column  align-items-center">
        <p class="card-text">
          ვეხმარებით ადამიანებს მეტი შემოსავლების მიღებაში
        </p>
        <div class="link d-flex flex-column">
          <button @click="setCookie('vacancy')" type="button" class="btn">
            ვაკანსიები
          </button>
          <button @click="setCookie('training')" type="button" class="btn mt-3">
            აკადემია
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue3-loading-overlay';

export default {
  name: "WelcomePage",
  data() {
    return {
      isLoading: true,
    }
  },
  components: {
    Loading
  },
  methods: {
    onCancel() {

    },
    setCookie(status) {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 3 * 60 * 60 * 1000); // Adding 3 hours in milliseconds

      document.cookie = "dkge=WelcomePage; expires=" + expirationDate.toUTCString();

      if (document.cookie) {
        if (status === 'vacancy') {
          this.$router.push('/Home');
        } else {
          // this.$router.push('/Training');
          window.location.href = `https://academy.dk.ge`;
        }
      } else {
        alert("Cookie can't be set!");
      }
    },
    checkCookie() {
      const value = document.cookie;
      const checkCookie = value.match("dkge=WelcomePage");
      if (checkCookie) {
          this.isLoading = true;
          this.$router.push('/Home');
      }
    }
  },
  mounted() {
    this.checkCookie();
    setTimeout(() => {
      this.isLoading = false
    },1000)
  }
}
</script>


<style scoped>

.card {
  margin-top: 141px;
  width: auto;
  border: none;
  padding: 0;
}

.card-img-top {
  width: 400px;
  height: 400px;
  object-fit: cover;
}


.card-body {
  padding: 0;
  margin-top: 64px;
}

.card-text {
  width: 546px;
  height: auto;
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;

  text-align: center;
  font-feature-settings: 'case' on;

  color: var(--Second-Color);
  margin: 0;
}

.btn {
  height: 60px;
  width: 442px;
  border-radius: 20px;
  background: var(--First-Color);
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-top: 52px;
  border: none;

  color: var(--Second-Color)!important;

  transition: all 0.3s;
}


.btn:hover {
  transform: translateY(-3px);
  background: var(--Second-Color-Background-Btn-Hover) !important;
}


@media (max-width: 1600px) {
  .card {
    margin-top:70px;
  }
}
@media (max-width: 1399px) {
  .card {
    margin-top: 50px;
  }
  .card-body {
    margin-top:50px
  }
  .btn {
    width: 380px;
    height: 55px;
    margin-top:50px
  }
  .card-img-top {
    width: 300px;
    height: 300px;
  }
}
@media (max-width: 650px) {
  .card-img-top {
    width: 390px;
    height: 390px;
  }

  .card-body {
    margin-top: 68px;
    padding: 0;
    width: 500px;
  }

  .card-text {
    font-size: 22px;
    line-height: 30px;
    width: 100%;
  }

  .btn {
    margin-top: 68px;
  }

}


@media (max-width: 600px) {
  .card-img-top {
    width: 385px;
    height: 385px;
  }

  .card-body {
    width: 450px;
  }

  .btn {
    height: 60px;
    width: 400px;
    font-weight: 500;
    font-size: 17px;
    line-height: 19px;
  }

}


@media (max-width: 550px) {
  .card-img-top {
    width: 375px;
    height: 375px;
  }

  .card-body {
    width: 430px;
  }

  .card-text {
    font-size: 23px;
  }


}


@media (max-width: 500px) {
  .card-img-top {
    width: 350px;
    height: 350px;
  }

  .card-body {
    width: 390px;
  }

  .card-text {
    font-size: 19px;
    line-height: 27px;
  }

  .btn {
    height: 55px;
    width: 370px;
    font-size: 17px;
  }
}

@media (max-width: 428px) {
  .card {
    margin-top: 141px;
  }
  .card-img-top {
    width: 328px;
    height: 328px;
  }

  .card-body {
    width: 368px;
  }

  .card-text {
    font-size: 18px;
    line-height: 28px;
  }

  .btn {
    width: 368px;
    font-size: 16px;
  }

}

@media (max-width: 427px) {
  .card {
    margin-top: 60px;
  }
}
@media (max-width: 400px) {
  .card-img-top {
    width: 318px;
    height: 318px;
  }

  .card-body {
    width: 348px;
  }


  .btn {
    width: 348px;
    font-size: 15px;
  }

}

@media (max-width: 380px) {
  .card-img-top {
    width: 300px;
    height: 300px;
  }

  .card-body {
    width: 328px;
  }

  .btn {
    height: 50px;
    width: 318px;
  }

}

@media (max-width: 350px) {
  .card-img-top {
    width: 280px;
    height: 280px;
  }

  .card-body {
    width: 318px;
  }

  .card-text {
    font-size: 17px;
  }

  .btn {
    width: 280px;
  }

}
@media (max-width: 310px) {
  .card-img-top {
    width: 260px;
    height: 260px;
  }

  .card-body {
    width: 288px;
  }

  .card-text {
    font-size: 16px;
  }

  .btn {
    width: 260px;
  }

}

</style> -->