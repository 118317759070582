<template>
  <div>
    <section class="category">
      <div class="container">
        <div class="row">
          <div class="col-12" v-if="Array.isArray(superVip.super_vip) && superVip.super_vip.length != 0">
            <div class="category-box">
              <h4> SUPER VIP ვაკანსიები</h4>
            </div>
          </div>
          <div id="super_vip" class="col-12 vacancy_card">
            <div class="info-box">
              <div class="info" v-for="item in superVip.super_vip" :key="item.id">
                <div class="section-one">
                  <router-link :to="'/VacancyDetails/'+item.id">
                    <img v-if="item.CompanyDetail.logo!=''" class="company-logo" :src="item.CompanyDetail.logo" alt="vacancy logo">
                    <img v-else class="company-logo" src="../assets/image/noavatar.jpeg" alt="vacancy logo">
                  </router-link>
                  <div class="inside-box">
                    <router-link :to="'/VacancyDetails/'+item.id"><h6>{{ item.PositionDetail.text }}</h6></router-link>
                    <router-link :to="'/VacancyDetails/'+item.id"><p :title="item.IndustryDetail.text">{{ item.IndustryDetail.text }}</p></router-link>
                    <router-link :to="'/Company/'+item.CompanyDetail.id"><p class="company-link">{{ item.CompanyDetail.brandname }}</p></router-link>
                  </div>
                </div>
                <router-link :to="'/VacancyDetails/'+item.id" class="section-two">
                  <div class="col-5">
                    <div class="inside-box-two">
                      <img src="../assets/image/icons/location-icon.svg" alt="">
                      <p>{{ item.VacancyLocation.name }}</p>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="inside-box-two">
                      <img src="../assets/image/icons/experience-icon.svg" alt="">
                      <p v-if="item.work_experience=='1'">გამოცდილების გარეშე</p>
                      <p v-if="item.work_experience=='2'">დამწყები</p>
                      <p v-if="item.work_experience=='3'">საშუალო დონე</p>
                      <p v-if="item.work_experience=='4'">პროფესიონალი</p>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="inside-box-special">
                      <img src="../assets/image/icons/lari-icon.svg" alt="">
                      <p v-if="item.amount_type=='1'">{{ Number(item.avarage_min_bonus).toLocaleString() }}{{item.CurrencyDetail.text}} -  {{ Number(item.avarage_max_bonus).toLocaleString()  }}{{item.CurrencyDetail.text}}</p>
                      <p v-if="item.amount_type=='2'">{{ Number(item.min_amount).toLocaleString() }}{{ item.CurrencyDetail.text }} - {{ Number(item.max_amount).toLocaleString() }}{{ item.CurrencyDetail.text }}</p>
                      <p v-if="item.amount_type=='3'">{{ Number(item.fixed_amount).toLocaleString() }}{{ item.CurrencyDetail.text }}</p>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="inside-box-special">
                      <img src="../assets/image/icons/time-progress-icon.svg" alt="">
                      <p>{{ item.work_schedule.name}}</p>
                    </div>
                  </div>
                </router-link>
              </div>

            </div>
          </div>

          <div class="col-12 mt-5">
            <div class="category-box">
              <h4>კატეგორიები</h4>
            </div>
          </div>

          <div id="category" class="col-12">
            <div class="content-box">
              <router-link :to="`/VacancyList`+'?type='+'vip'" class="box">
                <img src="../assets/icons/VIP.png" alt="vacancy category">
                <div class="detail">
                  <h6 class="text-center">VIP ვაკანსიები</h6>
                  <p>({{superVip.cnt_vip}} ვაკანსია)</p>
                </div>
              </router-link>
              
              <router-link v-for="item in ResponseData" :key="item.id" :to="`/VacancyList`+'?type='+item.type+'&id='+item.id" class="box">
                <img :src="item.file" alt="vacancy category">
                <div class="detail">
                  <h6 class="text-center">{{ item.name }}</h6>
                  <p>({{ item.cnt }} ვაკანსია)</p>
                </div>
              </router-link>
            </div>
          </div>


          <div class="col-12 mt-5" v-if="Array.isArray(superVip.vip) && superVip.vip.length != 0">
            <div class="category-box">
              <h4>VIP ვაკანსიები</h4>
            </div>
          </div>

          <div id="vip" class="col-12 vacancy_card">
            <div class="info-box">
              <div class="info" v-for="item in superVip.vip" :key="item.id">
                <div class="section-one">
                  <router-link :to="'/VacancyDetails/'+item.id">
                    <img v-if="item.CompanyDetail.logo!=''" class="company-logo" :src="item.CompanyDetail.logo" alt="vacancy logo">
                    <img v-else class="company-logo" src="../assets/image/noavatar.jpeg" alt="vacancy logo">
                  </router-link>
                  <div class="inside-box">
                    <router-link :to="'/VacancyDetails/'+item.id"><h6>{{ item.PositionDetail.text }}</h6></router-link>
                    <router-link :to="'/VacancyDetails/'+item.id"><p :title="item.IndustryDetail.text">{{ item.IndustryDetail.text }}</p></router-link>
                    <router-link :to="'/Company/'+item.CompanyDetail.id"><p class="company-link">{{ item.CompanyDetail.brandname }}</p></router-link>
                  </div>
                </div>
                <router-link :to="'/VacancyDetails/'+item.id" class="section-two">
                  <div class="col-5">
                    <div class="inside-box-two">
                      <img src="../assets/image/icons/location-icon.svg" alt="">
                      <p>{{ item.VacancyLocation.name }}</p>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="inside-box-two">
                      <img src="../assets/image/icons/experience-icon.svg" alt="">
                      <p v-if="item.work_experience=='1'">გამოცდილების გარეშე</p>
                      <p v-if="item.work_experience=='2'">დამწყები</p>
                      <p v-if="item.work_experience=='3'">საშუალო დონე</p>
                      <p v-if="item.work_experience=='4'">პროფესიონალი</p>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="inside-box-special">
                      <img src="../assets/image/icons/lari-icon.svg" alt="">
                      <p v-if="item.amount_type=='1'">{{ Number(item.avarage_min_bonus).toLocaleString() }}{{item.CurrencyDetail.text}} -  {{ Number(item.avarage_max_bonus).toLocaleString()  }}{{item.CurrencyDetail.text}}</p>
                      <p v-if="item.amount_type=='2'">{{ Number(item.min_amount).toLocaleString() }}{{ item.CurrencyDetail.text }} - {{ Number(item.max_amount).toLocaleString() }}{{ item.CurrencyDetail.text }}</p>
                      <p v-if="item.amount_type=='3'">{{ Number(item.fixed_amount).toLocaleString() }}{{ item.CurrencyDetail.text }}</p>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="inside-box-special">
                      <img src="../assets/image/icons/time-progress-icon.svg" alt="">
                      <p>{{ item.work_schedule.name}}</p>
                    </div>
                  </div>
                </router-link>
              </div>

            </div>
          </div>

          <div class="col-12 text-center mt-4">

            <router-link to="/VacancyList" class="home-btn">ყველა ვაკანსია</router-link>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default{
  name: 'Category',
  data() {
    return {
      ResponseData: [{
        name: '',
        file:'',
        cnt: ''
      }],
      superVip: []
    }
  },
  methods: {
    categoryData() {
      this.axios.get(process.env.VUE_APP_BACKEND_URL+'/public/categories')
          .then(response => {
            this.ResponseData = response.data;
            this.isLoading = false;
          });
    },
    getSuperVip() {
      this.isLoading = true;
        this.axios.get('https://recruting.dkcapital.ge/api/public/vacancy/super_vip_list')
          .then(response => {
            this.superVip = response.data;
            this.isLoading = false;
          });
    }
  },
  mounted() {
    this.categoryData();
    this.getSuperVip();
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'FiraGO-Medium.ttf';
  src: url(../assets/fonts/FiraGO-Medium.ttf);
}
.category-box{
  margin-bottom: 36px;

}
.content-box{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.box{
  display: grid;
  justify-items: center;
  width: 100%;
  height: 190px;
  padding:26px 3px 25px;
  background: #FFFFFF;
  color: rgba(66, 66, 66, 0.5);
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.3s;
}
.box:hover {
  border: 1px solid var(--Second-Color-Box-Hover);
  transform: translateY(-4px);
}

.box .detail {
  text-align: center;
}

h4{
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  color: #424242;
}
/*.col{*/
/*  display: grid;*/
/*  justify-items: center;*/
/*  max-width: 237px;*/
/*  width: 100%;*/
/*  height: 190px;*/
/*  padding: 26px 0 25px;*/
/*  background: #FFFFFF;*/
/*  color: rgba(66, 66, 66, 0.5);*/
/*  border: 1px solid rgba(66, 66, 66, 0.1);*/
/*  border-radius: 10px;*/
/*  text-decoration: none;*/
/*}*/

#category img{
  width: 56px;
  height: 56px;
}

h6{
  margin-top: 20px;
  margin-bottom: 0!important;
  font-family: var(--First-Font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #424242;
}
/*.vacancy-calc-box{*/
/*  height:22px;*/
/*}*/
p{
  margin-top:0!important;
  margin-bottom: 0;
  font-family: var(--First-Font);
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
}

@media screen and (max-width:1366px) {
  .category-box{
    margin-bottom: 32px;
  }
  h4{
    font-size: 18px;
    line-height: 22px;
  }
  .content-box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px
  }
  /*.col{*/
  /*  max-width: 237px;*/
  /*  width: 100%;*/
  /*  height: 160px;*/
  /*  padding-top: 23px;*/
  /*  padding-bottom: 20px;*/
  /*}*/

  #category img{
    width: 56px;
    height: 56px;
  }
  h6{
    font-size: 14px;
    line-height: 25px;
  }
  p{
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media screen and (max-width:1199px) {
  .category-box{
    margin-bottom: 0;
  }
  .content-box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
  }
}
@media screen and (max-width:1200px) {
  .row{
    gap: 25px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  /*.col{*/
  /*  padding-top: 20px;*/
  /*  padding-bottom: 20px;*/
  /*}*/
  h6{
    font-size: 14px;
    line-height: 25px;
  }
  #category img{
    width: 50px;
    height: 50px;
  }
  p{
    font-size: 13px;
    line-height: 22px;
  }
}
@media screen and (max-width:992px) {
  .content-box{
    gap: 5px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  h6{
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width:767px) {
  .content-box{
    gap: 5px;
    grid-template-columns: 1fr 1fr;
  }
  .box{
    max-width: 100%;
  }
}

@media screen and (max-width:544px) {
  .box{
    /*max-width: 250px;*/
    width: 100%;
    height: 174px;
    padding-top: 17px;
    padding-bottom: 17px;
  }
  .content-box{
    gap: 5px;
    grid-template-columns: 1fr 1fr;
  }
  .category-box{
    margin-top: 44px;
  }
  h4{
    font-size: 18px;
    line-height: 19px;
  }
  #category img{
    width: 50px;
    height: 50px;
  }
  h6{
    margin-top: 10px;
    margin-bottom: 0.3rem;
    font-size: 14px;
    line-height: 18px;
  }
  p{
    font-size: 12px;
    margin-top: 8px;
    line-height: 20px;

  }
}

@media screen and (max-width:472px) {

  .box{
    display: flex;
    align-items: center;
    height: 145px;
    width: 100%;
    padding: 30px;
    gap: 30px;
  }
  .box .detail {
    text-align: start;
  }
  .box .detail h6 {
    font-size: 16px;
    text-align: start!important;
    line-height: 25px;
    margin-top: 0;
    margin-bottom: 10px!important;
  }
  .content-box{
    grid-template-columns: 1fr;
    gap: 5px;
    justify-content: center;
  }
  .category-box{
    margin-bottom: 0;
    margin-top: 44px;
  }
  h4{
    font-size: 16px;
  }
  #category img{
    width: 50px;
    height: 50px;
  }
  h6{
    font-size: 14px;
  }
  p{
    font-size: 14px;
    margin-top: 4px!important;
  }
}
@media screen and (max-width: 390px) {
  .content-box{
    grid-template-columns: 1fr ;
    gap: 5px;
  }
  .box .detail h6{
    font-size: 14px;
  }
  .box .detail p{
    font-size: 14px;
  }
  .box {
    max-width: 100%;
  }
}
@media screen and (max-width: 362px) {
  #category #category img{
    width: 46px;
    height: 46px;
  }
  .box .detail h6{
    font-size: 14px;
  }
  .box .detail p{
    font-size: 14px;
  }
}





.vacancy_card h4{
  margin-bottom: 0;
}

.vacancy_card a{
  text-decoration: none;
}

.vacancy_card .inside-box a:nth-child(2) {
  overflow: hidden;
}

.vacancy_card .inside-box a:nth-child(2) p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vacancy_card h6{
  font-family: var(--First-Font);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;

  color: #424242;

  width: 90%;

}
.vacancy_card p{
  font-family: var(--Third-Font);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;

  color: rgba(66, 66, 66, 0.5);
}
.vacancy_card .company-link{
  font-family: var(--Third-Font);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0em;

  color: #2200CC;
}

.vacancy_card .title-sect{
  display: grid;
  gap: 16px;
  padding-bottom: 36px;
}
.vacancy_card .title-box{
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: flex-end;
}
.vacancy_card .title{
  max-width: 138px;
  width: 100%;
  font-family: var(--First-Font);
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 500;

  font-feature-settings: 'case' on;
  color: #424242;

}
.vacancy_card  .vacancy-calc{
  /*max-width: 88px;*/
  width: 100%;
  font-family: var(--First-Font);
  font-size: 13px;
  line-height: 21px;
  font-weight: 500;

  color: rgba(66, 66, 66, 0.5);
}
.vacancy_card  .info-box{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.vacancy_card .info{
  display: grid;
  gap: 8px;

  padding: 18px 18px 22px;
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 10px;
  transition: all 0.3s;

}
.vacancy_card .info:hover {
  border: 1px solid var(--Second-Color-Box-Hover);
  transform: translateY(-4px);
}
.vacancy_card .inside-box{
  display: grid;
  gap: 5px;
}
.vacancy_card .inside-box-two{
  display: flex;
  align-items: center;
  gap: 9.04px;
}
.vacancy_card .inside-box-special{
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 9px;
}
.vacancy_card .section-one{
  display: flex;
  align-items: start;
  height: 100px;
  gap: 16px;

}
.vacancy_card .section-two{
  display: flex;
  flex-wrap: wrap;
}
.vacancy_card .company-logo {
  width: 80px;
  /*height: 57px;*/
  /*border: 1px solid rgba(66, 66, 66, 0.1);*/
  /*border-radius: 50%;*/
  /*object-fit: cover;*/
  aspect-ratio: 1/1;
  object-fit: contain;
  object-position: top;
}
.vacancy_card .search-alert {
  font-family: var(--Third-Font);
  font-size: 16px;
}
@media screen and (max-width:1399px) {
  .vacancy_card .info-box{
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 18px;
  }
  .vacancy_card .info{
    gap: 8px;
    padding:21px 20px;
  }
  .vacancy_card .inside-box-special{
    margin-top: 10px;
  }
  .vacancy_card p{
    font-size: 13px;
  }
  @media screen and (max-width:1200px) {
    .vacancy_card .info-box{
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 18px;
    }
    .vacancy_card .info{
      gap: 8px;
      padding:21px 18px;
    }
    .vacancy_card .inside-box-special{
      margin-top: 10px;
    }
    .vacancy_card p{
      font-size: 13px;
    }
  }

  @media screen and (max-width:991px) {
    .vacancy_card .title-sect{
      padding-right: 18px;
    }
    .vacancy_card .info-box{
      grid-template-columns: 1fr 1fr;
      width: 100%;
      gap: 14px;
    }
    .vacancy_card .info{
      gap: 8px;
      padding:21px 18px;
    }
    .vacancy_card .inside-box-special{
      margin-top: 10px;
    }
    .vacancy_card p{
      font-size: 12px;
    }
  }

}
@media screen and (max-width:768px) {
  .vacancy_card .info-box{
    grid-template-columns: 100%;
    width: 100%;
    gap: 16px;
  }
  .vacancy_card .info{
    gap: 8px;
    padding-top: 21px;
    padding-bottom: 21px;
  }
  .vacancy_card .inside-box-special{
    margin-top: 10px;
  }
  .vacancy_card p{
    font-size: 13px;
  }
}
@media screen and (max-width:428px) {
  .vacancy_card .info-box{
    grid-template-columns: 100%;
    width: 100%;
    gap: 16px;
  }
  .vacancy_card .info{
    gap: 8px;
    padding:21px 23px 21px 25px;
  }
  .vacancy_card .inside-box-special{
    margin-top: 10px;
  }
  .vacancy_card p{
    font-size: 13px;
  }
}
@media screen and (max-width:375px) {
  .vacancy_card .title-sect{
    padding-left: 0;
    padding-right: 0;
  }
  .vacancy_card .info-box{
    grid-template-columns: 100%;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    gap: 16px;
  }
  .vacancy_card .info{
    gap: 8px;
    padding:21px 13px;
  }
  .vacancy_card .inside-box-special{
    margin-top: 10px;
  }
  .vacancy_card .company-link{
    font-size: 12px;
  }
  .vacancy_card h6{
    font-size: 13px;
  }
  .vacancy_card p{
    font-size: 12px;
  }
  .vacancy_card .company-logo{
    width: 60px;
    /*height: 50px;*/
  }
}

@media screen and (max-width:320px) {
  .vacancy_card .title-sect{
    padding-left: 0;
    padding-right: 0;
  }
  .vacancy_card .info-box{
    grid-template-columns: 100%;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    gap: 16px;
  }
  .vacancy_card .info{
    gap: 8px;
    padding:21px 10px;
  }
  .vacancy_card .inside-box-special{
    margin-top: 10px;
  }
  .vacancy_card .company-link{
    font-size: 11px;
  }
  .vacancy_card h6{
    font-size: 13px;
  }
  .vacancy_card p{
    font-size: 11px;
  }
  .vacancy_card .company-logo{
    width: 50px;
    height: 50px;
  }
}

#super_vip .info {
  border: 1px solid #ffb800;
  position: relative;
  overflow: hidden;
  

  background-color: #ffb70010;
}

#super_vip .info::before {
  content: "";
  width: 45px;
  height: 45px;
  background-image: url('../assets/icons/Super_Vip_noBG.png');
  background-size: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: #ffb800;
  /* border-top-left-radius: 10px; */
  border-bottom-left-radius: 50%;
  /* border-bottom-right-radius: 10px; */
  

  position: absolute;
  top:0;
  right: 0;
}


#vip .info {
  border: 1px solid var(--Fourth-Color);
  position: relative;
  overflow: hidden;
  

  background-color: #246af609;
}

#vip .info::before {
  content: "";
  width: 45px;
  height: 45px;
  background-image: url('../assets/icons/VIP_noBG.png');
  background-size: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: var(--Fourth-Color);
  /* border-top-left-radius: 50%; */
  border-bottom-left-radius: 50%;
  /* border-bottom-right-radius: 50%; */
  

  position: absolute;
  top:0;
  right: 0;
}

.home-btn {
    display: inline-block;

    background-color: var(--Fourth-Color) !important;

    border: 1px solid transparent;
    border-radius: 10px;

    padding: 15px 20px;

    font-family: var(--Third-Font);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: lowercase;

    color: #ffffff !important;
    transition: 0.3s;
}

.home-btn:hover {
    background: #1d32f4 !important;
}

</style>
