<template>
  <loading :active="isLoading"
           :can-cancel="true"
         

           :z-index="10000"
           :color="'var(--First-Color)'"></loading>
  <div>
    <Header/>
    <Home_Component/>
    <Category/>
<!--    <Trainings/>-->
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Home_Component from '@/components/Home.vue'
import Category from '@/components/Category.vue'
// import Trainings from '@/components/Trainings.vue'
import Footer from '@/components/NewFooter.vue'

import Loading from 'vue3-loading-overlay';

export default {
  name: 'Home',
  data() {
    return {
      isLoading: true,
    }
  },
  components: {
    Header,
    Home_Component,
    Category,
    // Trainings,
    Footer,

    Loading
  },
  methods: {
 
  },
  mounted() {

    window.scroll(0,0);
    setTimeout(() => {
      this.isLoading = false
    },1000)
  }
}
</script>

<style scoped>

</style>